import { getDataFromStorage, setDataToStorage } from '../utils';

/**
 * This class manage user storage data
 */

class User {
    static getToken(fromSession?: boolean | unknown): unknown {
        let sessionFlag: boolean | unknown = fromSession;

        if (typeof fromSession !== 'boolean')
            sessionFlag = !this.getKeepConnected();

        return getDataFromStorage('token', sessionFlag);
    }

    static setToken(token: string, toSession = false): void {
        setDataToStorage('token', token, toSession);
    }

    static getKeepConnected(): unknown {
        return getDataFromStorage('keepConnected');
    }

    static setKeepConnected(keepConnected: boolean): Promise<void> {
        return new Promise(resolve =>
            resolve(setDataToStorage('keepConnected', keepConnected))
        );
    }
}

export default User;
