import { Switch } from 'antd';
import { useThemeContext } from '../../../contexts/ThemeProvider';
import { isDark } from '../../../utils';

export default function ThemeSwitch() {
    const themeConfig = useThemeContext();
    const enableDarkMode = isDark();

    const renderMoon = () => (
        <div
            style={{
                borderRadius: '50%',
                overflow: 'hidden',
                height: 18,
                width: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    marginLeft: 2,
                    boxShadow: '-2px 2px 0 0 #ffe300',
                }}
            />
        </div>
    );

    const renderSun = () => (
        <div
            style={{
                borderRadius: '50%',
                height: 18,
                width: 18,
                marginTop: 6,
                marginLeft: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#ffe300',
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    boxShadow: '0 0 6px 1px #ffe300',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2,
                }}
            />
        </div>
    );

    return (
        <Switch
            checkedChildren={renderMoon()}
            unCheckedChildren={renderSun()}
            checked={enableDarkMode}
            style={{ backgroundColor: enableDarkMode ? '#6117dc' : '#18a8ff' }}
            onClick={status => themeConfig.setDarkMode(status)}
        />
    );
}
