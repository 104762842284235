import { ReactElement, Suspense } from 'react';

import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

import { Route, Routes as Switch } from 'react-router-dom';

import LoadingPage from './pages/loadingPage';

import RenderComponent from './components/renderComponent';

import Graphs from './pages/graphs/Graphs';
import Home from './pages/home/Home';
import Icons from './pages/icons/Icons';
import { IMenuKey } from './utils/menuOptions';
import Login from './pages/authentication/login/Login';
import SignUp from './pages/authentication/signUp/SignUp';
import Account from './pages/account/Account';

const Routes = ({ t }: { t: TFunction }): ReactElement<unknown> => {
    const routes: {
        key?: IMenuKey;
        path: string;
        useMainLayout?: boolean;
        absoluteTabTitle?: boolean;
        publicRoute?: boolean;
        authRoute?: boolean;
        title: string;
        component: JSX.Element;
    }[] = [
        {
            path: '/home',
            key: 'home',
            useMainLayout: true,
            title: t('tab.home'),
            component: <Home />,
        },
        {
            path: 'graphs/*',
            key: 'graphs',
            useMainLayout: true,
            title: t('tab.graphs'),
            component: <Graphs />,
        },
        {
            path: 'icons/*',
            key: 'icons',
            useMainLayout: true,
            title: t('tab.icons'),
            component: <Icons />,
        },
        {
            path: '/account',
            key: 'account',
            useMainLayout: true,
            title: t('tab.account'),
            component: <Account />,
        },
        {
            path: '/signUp',
            authRoute: true,
            publicRoute: true,
            absoluteTabTitle: true,
            title: t('tab.authentication'),
            component: <SignUp />,
        },
        {
            path: '/',
            authRoute: true,
            publicRoute: true,
            absoluteTabTitle: true,
            title: t('tab.authentication'),
            component: <Login />,
        },
        {
            path: '/auth',
            authRoute: true,
            publicRoute: true,
            absoluteTabTitle: true,
            title: t('tab.authentication'),
            component: <Login />,
        },
        {
            path: '/*',
            authRoute: true,
            publicRoute: true,
            absoluteTabTitle: true,
            title: t('tab.authentication'),
            component: <Login />,
        },
    ];

    return (
        <Suspense fallback={<LoadingPage />}>
            <Switch>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <RenderComponent
                                menuKey={route.key}
                                useMainLayout={route.useMainLayout}
                                absoluteTabTitle={route.absoluteTabTitle}
                                authRoute={route.authRoute}
                                publicRoute={route.publicRoute}
                                tabTitle={route.title}
                            >
                                {route.component}
                            </RenderComponent>
                        }
                    />
                ))}
            </Switch>
        </Suspense>
    );
};

export default withTranslation()(Routes);
