import React, { ReactElement } from 'react';

import { AuthProvider } from './contexts/AuthContext';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { ThemeProvider } from './contexts/ThemeProvider';
import { UserContextProvider } from './contexts/UserContext';

const Providers = ({
    children,
}: {
    children: React.ReactNode;
}): ReactElement => {
    // Do not delete the code commented on isLoading as it is still being resolved (but it was not the objective of this task)

    return (
        <AuthProvider>
            <UserContextProvider>
                <ThemeProvider>
                    <LayoutContextProvider>
                        {/* {isLoading && <LoadingPage asOverlay />} */}
                        {children}
                    </LayoutContextProvider>
                </ThemeProvider>
            </UserContextProvider>
        </AuthProvider>
    );
};

export default Providers;
