import { message } from 'antd/lib';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRequest } from '../hooks/useRequest';
import UserController from '../structures/controllers/User';
import { clearStorage } from '../utils';
import { ILogin, ISignUp } from '../utils/interfaces';

type ILoggedUser = boolean | undefined | null;
interface IAuthProvider {
    loading?: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    isUserLoggedIn?: boolean | null;
    login: (values: ILogin) => void;
    signUp: (values: ISignUp) => void;
    logout: () => void;
}

interface IAuthApiError {
    name: string;
    status: number;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation();
    const [signUpRequest] = useRequest(UserController.signUp);
    const [loginRequest] = useRequest(UserController.login);
    const [loading, setLoading] = useState(false);
    const [isLoggedUser, setIsLoggedUser] = useState<ILoggedUser>(undefined);

    const navigate = useNavigate();

    const showError = (msg: string) => {
        message.error({
            content: msg,
            style: { marginTop: 64 },
        });
    };
    const showSignUpError = ({ status }: IAuthApiError) => {
        message.error({
            content: t(`errors.${status}.msg`),
            style: { marginTop: 64 },
        });
    };

    const handleSignUp = (values: ISignUp) => {
        setLoading(true);
        signUpRequest(values)
            .then(token => {
                sessionStorage.setItem('token', `"${token}"`);
                setLoading(false);
                setIsLoggedUser(true);
                navigate('/home');
            })
            .catch(err => {
                setLoading(false);
                showSignUpError(err);
            });
    };

    const handleLogin = (values: ILogin) => {
        setLoading(true);
        setIsLoggedUser(null);
        loginRequest(values)
            .then(token => {
                UserController.uploadUserData(
                    token.token,
                    values.keepConnected
                ).then(() => {
                    setLoading(false);
                    setIsLoggedUser(true);
                    navigate('/home');
                });
            })
            .catch(() => {
                setLoading(false);
                showError('Não foi possível realizar o login');
            });
    };

    const handleLogout = () => {
        document.location.reload();
        clearStorage();
        setIsLoggedUser(false);
        navigate('/login');
    };

    return (
        <AuthContext.Provider
            value={{
                isUserLoggedIn: isLoggedUser,
                signUp: handleSignUp,
                login: handleLogin,
                logout: handleLogout,
                loading: loading,
                setLoading: setLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const AuthContextDefaultValue: IAuthProvider = {
    setLoading: () => {},
    login: () => {},
    signUp: () => {
        return;
    },
    logout: () => {
        return;
    },
};

export const AuthContext = createContext<IAuthProvider>(
    AuthContextDefaultValue
);

export const useAuthContext = () => useContext(AuthContext);
