import { LeftOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Popconfirm, Row } from 'antd';
import { TFunction } from 'i18next';
import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthContainer from '../../../components/authentication/authContainer';
import TitleHeader from '../../../components/authentication/components/titleHeader/TitleHeader';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ISignUp } from '../../../utils/interfaces';

const { Password } = Input;

const SignUp = ({ t }: { t: TFunction }): ReactElement<unknown> => {
    const { signUp, loading } = useAuthContext();

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const handleCancel = () => {
        navigate('/');
    };

    const handleFormFinish = (values: ISignUp) => {
        signUp(values);
    };

    const CancelButton = ({ onClick }: { onClick?: () => void }) => (
        <Button danger size="large" type="primary" onClick={onClick}>
            {t('general.cancel')}
        </Button>
    );

    const termsText = `
    ${t('pages.signUp.terms.text')}
    <a href="${'/'}">
      ${t('pages.signUp.terms.link')}
    </a>
    `;

    const LoginForm = (
        <Form
            className="form"
            layout="vertical"
            form={form}
            onFinish={handleFormFinish}
            requiredMark={false}
            initialValues={{ keepConnected: false }}
        >
            <div>
                <Form.Item
                    validateFirst
                    name="username"
                    label={t('common.username')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredUsername'),
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined style={{ fontSize: 15 }} />}
                        placeholder={t('pages.signUp.placeholders.username')}
                    />
                </Form.Item>

                <Form.Item
                    validateFirst
                    name="email"
                    label={t('common.email')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredEmail'),
                        },
                        {
                            type: 'email',
                            message: t('input.rules.invalidEmail'),
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined style={{ fontSize: 15 }} />}
                        placeholder={t('pages.signUp.placeholders.email')}
                    />
                </Form.Item>

                <Form.Item
                    dependencies={['confirmPassword']}
                    validateFirst
                    name="password"
                    label={t('common.password')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredPassword'),
                        },
                        {
                            min: 6,
                            message: t(
                                'pages.createPassword.fields.rules.passwordMinLength'
                            ),
                        },
                    ]}
                >
                    <Password
                        prefix={<LockOutlined style={{ fontSize: 15 }} />}
                        placeholder={t('pages.signUp.placeholders.password')}
                    />
                </Form.Item>

                <Form.Item
                    dependencies={['password']}
                    validateFirst
                    name="confirmPassword"
                    label={t('common.confirmPassword')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredPassword'),
                        },
                        {
                            min: 6,
                            message: t(
                                'pages.createPassword.fields.rules.passwordMinLength'
                            ),
                        },
                        {
                            validator: (_, value) => {
                                const passwordErr = new Error(
                                    t(
                                        'pages.createPassword.fields.rules.passwordNotMatch'
                                    )
                                );
                                return value === form.getFieldValue('password')
                                    ? Promise.resolve()
                                    : Promise.reject(passwordErr);
                            },
                        },
                    ]}
                >
                    <Password
                        prefix={<LockOutlined style={{ fontSize: 15 }} />}
                        placeholder={t(
                            'pages.signUp.placeholders.confirmPassword'
                        )}
                    />
                </Form.Item>
            </div>
        </Form>
    );

    const cancelButton = (
        <Popconfirm
            title={t('pages.signUp.confirm.cancel')}
            okText={t('general.yes')}
            cancelText={t('general.no')}
            onConfirm={handleCancel}
        >
            <CancelButton />
        </Popconfirm>
    );
    const submitButton = (
        <Button
            size="large"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
        >
            {t('pages.signUp.createAccount')}
        </Button>
    );

    const backButton = (
        <div>
            <Button
                size="large"
                type="text"
                icon={<LeftOutlined />}
                onClick={handleCancel}
            >
                {t('general.back')}
            </Button>
        </div>
    );

    const formHeader = (
        <TitleHeader
            centered
            titleText={t('pages.signUp.title')}
            subtitleText={t('pages.signUp.subtitle')}
        />
    );

    const formBody = (
        <>
            {LoginForm}
            <Row className="terms-text">
                <span
                    dangerouslySetInnerHTML={{
                        __html: termsText,
                    }}
                />
            </Row>
        </>
    );

    return (
        <div>
            <Row justify="center" align="middle" className="hero-container">
                <AuthContainer>
                    <Card bordered={false}>
                        <div style={{ textAlign: 'center' }}>{backButton}</div>
                        <div style={{ marginBottom: 12, marginTop: 12 }}>
                            {formHeader}
                        </div>
                        {formBody}
                        <div
                            style={{
                                marginTop: 12,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            {cancelButton}
                            {submitButton}
                        </div>
                    </Card>
                </AuthContainer>
            </Row>
        </div>
    );
};

export default withTranslation()(SignUp);
