import { ReactElement } from 'react';

import { Layout } from 'antd';

import useToken from '../../../hooks/useToken';
import AvatarMenu from '../avatarDropdown/AvatarDropdown';
import ThemeSwitch from '../themesSwitch/ThemeSwitch';
import TranslateMenu from '../translateDropdown/TranslateDropDown';

const { Header } = Layout;

/**
 * @description Styled panel Header.
 * @property {boolean} [collapsed=false] If true, sidebar menu collapse
 * @property {string} [layoutColor=null] Layout color
 * @property {function} setCollapsed Set sidebar collapsed
 * @example
 * <LayoutHeader
 *   collapsed={false}
 *   layoutColor="FFFFFF"
 *   setCollapse={(value: bool) => collapsed = value}
 * />
 */

interface IHeader {
    collapsed: boolean;
    layoutColor?: string;
    setCollapsed: (b: boolean) => void;
}

const LayoutHeader = ({ collapsed }: IHeader): ReactElement => {
    const token = useToken();

    return (
        <Header
            style={{
                padding: 0,
                position: 'fixed',
                width: '100%',
                paddingLeft: collapsed ? 100 : 200,
                paddingRight: 24,
                display: 'flex',
                alignItems: 'center',
                zIndex: 1,
                gap: 12,
                justifyContent: 'end',
                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.16)',
                color: token.layout.textColor,
                backgroundColor: token.layout.backgroundColor,
            }}
        >
            <AvatarMenu />
            <TranslateMenu />
            <ThemeSwitch />
        </Header>
    );
};

export default LayoutHeader;
