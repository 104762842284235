import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Dropdown } from 'antd';

import EuaFlag from '../../../assets/eua-flag.svg';
import SpainFlag from '../../../assets/spain-flag.svg';
import BrazilFlag from '../../../assets/brazil-flag.svg';

import './index.scss';

/**
 * @description Floating component to change panel language.
 * @example
 * <I18n />
 */

const I18n = (): ReactElement<unknown> => {
    const { t, i18n } = useTranslation();

    const handleChangeLanguage = (language: string): void => {
        i18n.changeLanguage(language);
    };

    const languages = [
        {
            key: 'en-US',
            text: t('languages.enUS'),
            image: EuaFlag,
        },
        {
            key: 'pt-BR',
            text: t('languages.ptBR'),
            image: BrazilFlag,
        },
        {
            key: 'es',
            text: t('languages.es'),
            image: SpainFlag,
        },
    ];

    const getCurrentLanguage = () => {
        const lg = i18n.language;
        const langs = languages.map(e => e.key);

        switch (lg) {
            case 'en-US':
            case 'pt-BR':
            case 'es':
                return languages[langs.indexOf(lg)];

            default:
                return languages[langs.indexOf('pt-BR')];
        }
    };

    return (
        <Dropdown
            placement="bottomRight"
            trigger={['click']}
            menu={{
                items: languages.map(e => ({
                    key: e.key,
                    onClick: () => handleChangeLanguage(e.key),
                    label: (
                        <>
                            <Avatar
                                size={18}
                                src={e.image}
                                style={{ marginRight: 6 }}
                            />
                            <span>{e.text}</span>
                        </>
                    ),
                })),
            }}
        >
            <Avatar size={40} className="fixed-widgets">
                <Avatar size={22} src={getCurrentLanguage().image} />
            </Avatar>
        </Dropdown>
    );
};

export default I18n;
