import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { User } from '@supabase/supabase-js';
import { useRequest } from '../hooks/useRequest';
import UserController from '../structures/controllers/User';
import { IToken } from '../utils/interfaces';
import { useAuthContext } from './AuthContext';

interface IUserProvider {
    userData?: { user: User | null };
    decodedToken?: IToken;
    currentOrganizationId?: string;
    setUserData: (t: { user: User | null }) => void | undefined;
    setDecodedToken: (t: IToken) => void | undefined;
    setCurrentOrganizationId: (t: string) => void | undefined;
    fetchUser: () => void;
}

const UserContext = React.createContext<IUserProvider>({
    setUserData: () => {
        return;
    },
    setDecodedToken: () => {
        return;
    },
    setCurrentOrganizationId: () => {
        return;
    },
    fetchUser: () => {
        return;
    },
});

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
    const { isUserLoggedIn } = useAuthContext();
    const [getUser] = useRequest(UserController.getUser);
    const [userData, setUserData] = useState<{ user: User | null }>();
    const [decodedToken, setDecodedToken] = useState<IToken>();
    const [currentOrganizationId, setCurrentOrganizationId] =
        useState<string>();

    const fetchUser = () => getUser({}).then(res => setUserData(res));

    useEffect(() => {
        if (isUserLoggedIn) {
            fetchUser();
        }
    }, [isUserLoggedIn]);

    return (
        <UserContext.Provider
            value={{
                userData,
                decodedToken,
                currentOrganizationId,
                setUserData,
                setDecodedToken,
                setCurrentOrganizationId,
                fetchUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);
