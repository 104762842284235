import { DefaultOptionType } from 'antd/lib/select';
import { TFunction } from 'i18next';
import { theme } from 'antd';
import { Theme } from './enums';

/**
 * General utils functions
 */

export const setPageTitle = (title: string, absolute = false): void => {
    if (absolute) {
        document.title = title;
    } else {
        document.title = `Painel • ${title}`;
    }
};

export const getDataFromStorage = (
    keyName: string,
    fromSession: unknown = false
): unknown => {
    let storage = localStorage;
    if (fromSession) storage = sessionStorage;

    const value = storage.getItem(keyName);

    if (value) return JSON.parse(value);

    return value;
};

export const setDataToStorage = (
    keyName: string,
    keyValue: unknown,
    toSession: unknown = false
): void => {
    let storage = localStorage;
    if (toSession) storage = sessionStorage;

    const value = JSON.stringify(keyValue);

    storage.setItem(keyName, value);
};

export const clearStorage = (): void => {
    localStorage.clear();
    sessionStorage.clear();
};

export const isUUID = (value: string): boolean => {
    const uuiPattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuiPattern.test(value);
};

export const replaceQuoteFormat = (value: string): string => {
    return value.replace(/"/g, '\\"');
};

export const translateOptions = (
    t: TFunction,
    options: DefaultOptionType[]
): DefaultOptionType[] => {
    return options.map(({ label, value }) => ({
        label: typeof label === 'string' ? t(label) : label,
        value,
    }));
};

export const isDark = () => {
    const themeId = theme.useToken().theme.id;
    return themeId === Theme.Dark;
};
