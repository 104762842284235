import { useEffect } from 'react';

import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import User from '../../classes/User';
import { setPageTitle } from '../../utils';
import { IMenuKey } from '../../utils/menuOptions';

import { useLayoutContext } from '../../contexts/LayoutContext';
import MainLayout from '../layout/mainLayout/MainLayout';

export interface IRenderComponentProps {
    children: JSX.Element;
    menuKey?: IMenuKey;
    useMainLayout?: boolean;
    tabTitle: string;
    absoluteTabTitle?: boolean;
    publicRoute?: boolean;
    authRoute?: boolean;
    t: TFunction;
}
/**
 * @description Render page component inside Route.
 * @property {boolean | undefined} [absoluteTabTitle = false] Set navigator tab title without any modification
 * @property {boolean | undefined} [authRoute = false] Whether is an authentication route
 * @property {boolean | undefined} [publicRoute = false] Whether the route should'nt need a authentication token
 * @property {boolean | undefined} [useMainLayout = false] Render Component inside MainLayout
 * @property {IMenuKey | undefined} [menuKey] Sidebar Menu option key
 * @property {JSX.Element} [children] Component to render
 * @property {string} [tabTitle] Navigator tab title
 *
 * @example
 * <Route
 *   path="/home"
 *   render={() => (
 *     <RenderComponent
 *       menuKey="home"
 *       absoluteTabTitle
 *       tabTitle={t('tab.authentication')}
 *     >
 *       <Home />
 *     </RenderComponent>
 *   )}
 * />;
 */

const RenderComponent = ({
    children,
    tabTitle,
    menuKey,
    absoluteTabTitle = false,
    useMainLayout = false,
    publicRoute = false,
    authRoute = false,
    t,
}: IRenderComponentProps): JSX.Element => {
    const token = User.getToken();

    const { setSelectedMenu } = useLayoutContext();

    useEffect(() => {
        if (menuKey) {
            setSelectedMenu(menuKey);
        }
    }, [menuKey]);

    if (authRoute && token) {
        return <Navigate to="/home" replace />;
    } else if (publicRoute || token) {
        setPageTitle(t(tabTitle), absoluteTabTitle);

        if (useMainLayout) {
            return <MainLayout>{children}</MainLayout>;
        } else {
            return <>{children}</>;
        }
    } else {
        return <Navigate to="/auth" replace />;
    }
};

export default withTranslation()(RenderComponent);
