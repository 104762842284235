import { LeftOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Grid } from 'antd';
import { ReactElement, useEffect } from 'react';

import userImg from '../../../assets/user_icon.svg';

import { t } from 'i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useUserContext } from '../../../contexts/UserContext';

const { useBreakpoint } = Grid;

const AvatarMenu = (): ReactElement => {
    const { logout } = useAuthContext();
    const { userData, fetchUser } = useUserContext();

    const username = userData?.user?.user_metadata.username;

    const screens = useBreakpoint();

    const menu = {
        expandIcon: (
            <LeftOutlined
                style={{
                    position: 'relative',
                    float: 'left',
                    fontSize: '12px',
                    marginTop: '5px',
                }}
            />
        ),
        onClick: logout,
        items: [
            {
                key: 'logout',
                style: { paddingLeft: 24 },
                label: (
                    <>
                        <LogoutOutlined /> {t('headerMenu.logOut')}
                    </>
                ),
            },
        ],
    };

    useEffect(() => {
        if (!userData) {
            fetchUser();
        }
    }, [userData]);

    return (
        <div
            style={{
                display: 'flex',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '14px',
            }}
        >
            <Dropdown menu={menu} trigger={['click']}>
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img
                        style={{ marginRight: 12, height: 30 }}
                        src={userImg}
                    />
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            maxWidth: screens.xs ? 120 : 'unset',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {username}
                    </span>
                </div>
            </Dropdown>
        </div>
    );
};

export default AvatarMenu;
