import { Modal } from 'antd';
import { TFunction } from 'i18next';
import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';

interface IUpdateUserAccount {
    t?: TFunction;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const UpdateUserAccount = ({
    visible,
    setVisible,
}: IUpdateUserAccount): ReactElement<unknown> => {
    return (
        <Modal
            open={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            closable
            closeIcon
        >
            Edição de usuário
        </Modal>
    );
};

export default withTranslation()(UpdateUserAccount);
