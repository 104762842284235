import ptBrTranslations from './pt-br';
import enUsTranslations from './en-us';
import esTranslations from './es';

export type ISupportedLocale = 'pt-BR' | 'en-US' | 'es';

const translations = {
    'pt-BR': ptBrTranslations,
    'en-US': enUsTranslations,
    es: esTranslations,
};

export default translations;
