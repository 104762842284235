import { ReactElement } from 'react';

import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Card, Checkbox, Form, Input, Row } from 'antd';

import AuthContainer from '../../../components/authentication/authContainer';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import useToken from '../../../hooks/useToken';
import '../HeroStyle.scss';
import './Login.scss';

interface ILogin {
    email: string;
    password: string;
    keepConnected: boolean;
}

interface ILoginProps {
    t: TFunction;
    bordered?: boolean;
}

const { Password } = Input;

const Login = ({ t, bordered = true }: ILoginProps): ReactElement<unknown> => {
    const { login, loading } = useAuthContext();

    const [form] = Form.useForm();

    const token = useToken();

    const onFinish = (values: ILogin) => {
        login(values);
    };

    const HeaderLogin = (
        <header>
            <div
                className="decoration"
                style={{
                    backgroundColor: token.colorPrimary,
                }}
            />
            <div className="header-container">
                <h1 className="title">{t('pages.login.title')}</h1>
                <span className="subtitle">{t('pages.login.subtitle')}</span>
            </div>
        </header>
    );
    const LoginForm = (
        <Form
            className="form"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            form={form}
            initialValues={{ keepConnected: true }}
        >
            <div>
                <Form.Item
                    validateFirst
                    name="email"
                    label={t('common.email')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredEmail'),
                        },
                        {
                            type: 'email',
                            message: t('input.rules.invalidEmail'),
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined style={{ fontSize: 15 }} />}
                        placeholder={t('pages.signUp.placeholders.email')}
                    />
                </Form.Item>

                <Form.Item
                    validateFirst
                    name="password"
                    label={t('common.password')}
                    rules={[
                        {
                            required: true,
                            message: t('input.rules.requiredPassword'),
                        },
                        {
                            min: 6,
                            message: t(
                                'pages.createPassword.fields.rules.passwordMinLength'
                            ),
                        },
                    ]}
                >
                    <Password
                        prefix={<LockOutlined style={{ fontSize: 15 }} />}
                        placeholder={t('pages.signUp.placeholders.password')}
                    />
                </Form.Item>
            </div>

            <Row
                align="middle"
                justify="space-between"
                style={{ rowGap: '12px' }}
            >
                <div className="keep-connected">
                    <Form.Item name="keepConnected" valuePropName="checked">
                        <Checkbox>
                            {t('pages.login.fields.keepConnected')}
                        </Checkbox>
                    </Form.Item>
                </div>

                <Link to="/" className="custom-link">
                    {t('pages.login.fields.passwordRecovery')}
                </Link>
            </Row>

            <Row justify="end">
                <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{
                        width: '100%',
                    }}
                    loading={loading}
                >
                    {t('pages.login.fields.loginButton')}
                </Button>
            </Row>

            <Row style={{ columnGap: '12px' }}>
                <span>{t('pages.login.fields.createAccount.text')}</span>
                <Link to="/signUp" className="custom-link">
                    {t('pages.login.fields.createAccount.link')}
                </Link>
            </Row>
        </Form>
    );

    return (
        <div>
            <Row justify="center" align="middle" className="hero-container">
                <AuthContainer className="auth-container">
                    <Card className="login-card" bordered={bordered}>
                        {HeaderLogin}
                        {LoginForm}
                    </Card>
                </AuthContainer>
            </Row>
        </div>
    );
};

export default withTranslation()(Login);
