import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export default function SpainFlag(
    props: CustomIconComponentProps | React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1em"
            height="1em"
            viewBox="0 0 154 154"
            {...props}
        >
            <defs>
                <clipPath id="clip-path">
                    <circle
                        id="Elipse_228"
                        data-name="Elipse 228"
                        cx={77}
                        cy={77}
                        r={77}
                        transform="translate(776 336)"
                        fill="#fff"
                    />
                </clipPath>
            </defs>
            <g
                id="Grupo_de_m\xE1scara_12"
                data-name="Grupo de m\xE1scara 12"
                transform="translate(-776 -336)"
                clipPath="url(#clip-path)"
            >
                <g
                    id="Grupo_16729"
                    data-name="Grupo 16729"
                    transform="translate(-723.902 -143.713)"
                >
                    <path
                        id="Caminho_6686"
                        data-name="Caminho 6686"
                        d="M.5,12.5H205.155V91.213H.5Z"
                        transform="translate(1487.124 505.857)"
                        fill="#ff0"
                    />
                    <path
                        id="Caminho_6688"
                        data-name="Caminho 6688"
                        d="M.5,5.5H205.155V42.233H.5Z"
                        transform="translate(1487.124 476.124)"
                        fill="#fe0001"
                    />
                    <path
                        id="Caminho_6691"
                        data-name="Caminho 6691"
                        d="M204.655,66.228V97.713H5.248V66.228H204.655M31.485,24.248V19c12.7,0,20.7,15.239,21.037,15.884C52.523,34.884,38.48,24.248,31.485,24.248ZM61.937,37.7c1.753-3.358,9.781-13.449,16.776-13.449V19c-12.7,0-20.7,15.239-21.037,15.884Z"
                        transform="translate(1485 533.466)"
                        fill="#c74343"
                    />
                    <path
                        id="Caminho_6692"
                        data-name="Caminho 6692"
                        d="M11.248,17H6V58.98h5.248Zm41.98,0H47.98V58.98h5.248Z"
                        transform="translate(1510.485 524.971)"
                        fill="#c74343"
                    />
                    <g
                        id="Grupo_16726"
                        data-name="Grupo 16726"
                        transform="translate(1526.98 547.218)"
                    >
                        <path
                            id="Caminho_6693"
                            data-name="Caminho 6693"
                            d="M18.937,50.1c-3.731,0-6.517-.918-8.281-2.734A8.006,8.006,0,0,1,8.5,42.187V18.5H29.49V42.114C29.49,42.948,29.175,50.1,18.937,50.1Z"
                            transform="translate(-5.876 -15.876)"
                            fill="#f78f8f"
                        />
                        <path
                            id="Caminho_6694"
                            data-name="Caminho 6694"
                            d="M28.991,23.248V44.222c-.052,2.01-1.154,5.384-7.934,5.384-6.722,0-7.777-3.337-7.808-5.368V23.248H28.991M34.238,18H8V44.238S7.712,54.854,21.062,54.854,34.238,44.238,34.238,44.238V18Z"
                            transform="translate(-8 -18)"
                            fill="#c74343"
                        />
                    </g>
                    <g
                        id="Grupo_16727"
                        data-name="Grupo 16727"
                        transform="translate(1526.98 536.671)"
                    >
                        <path
                            id="Caminho_6695"
                            data-name="Caminho 6695"
                            d="M34.238,15.99,8,16.042V21.29l10.5,10.5h5.248l10.5-10.5Z"
                            transform="translate(-8 -15.99)"
                            fill="#c74343"
                        />
                    </g>
                    <g
                        id="Grupo_16728"
                        data-name="Grupo 16728"
                        transform="translate(1537.476 531.476)"
                    >
                        <path
                            id="Caminho_6696"
                            data-name="Caminho 6696"
                            d="M12.624,15a2.624,2.624,0,1,0,2.624,2.624A2.625,2.625,0,0,0,12.624,15Z"
                            transform="translate(-10 -15)"
                            fill="#c74343"
                        />
                    </g>
                    <path
                        id="Caminho_6690"
                        data-name="Caminho 6690"
                        d="M.5,27.5H205.155V64.233H.5Z"
                        transform="translate(1487.124 569.57)"
                        fill="#fe0001"
                    />
                </g>
            </g>
        </svg>
    );
}
