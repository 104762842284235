interface ILanguage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const modules = {
    home: 'Início',
    graphs: 'Gráficos',
    icons: 'Íconos',
    account: 'Mi cuenta',
};

const es: ILanguage = {
    translations: {
        tab: {
            authentication: 'Autenticación',
            signUp: 'Registro',
            validateEmail: 'Verificando correo electrónico...',
            registerCompany: 'Registrar Empresa',
            createPassword: 'Crear Contraseña',
            termsOfUse: 'Términos de Uso',
            companyCreated: 'Empresa Registrada',
            ...modules,
        },
        languages: {
            ptBR: 'Português',
            enUS: 'English',
            es: 'Español',
        },
        headerMenu: {
            logOut: 'Salir',
        },
        general: {
            logoAlt: 'Logotipo de Pro Solus',
            yes: 'Si',
            no: 'No',
            close: 'Cerrar',
            back: 'Devolver',
            save: 'Guardar',
            cancel: 'Cancelar',
            submit: 'Crea una cuenta',
            confirm: 'Confirmar',
            update: 'Para editar',
        },
        common: {
            email: 'Correo electrónico',
            password: 'Contraseña',
            phoneNumber: 'Teléfono',
            confirmPassword: 'confirmar seña',
            username: 'Nombre de usuario',
        },
        sidebar: {
            options: { ...modules },
        },
        input: {
            rules: {
                required: 'Campo obligatorio',
                requiredEmail: 'Se requiere correo electrónico',
                requiredPassword: 'Se requiere contraseña',
                requiredUsername: 'Se requiere el nombre de usuario',
                invalidEmail: 'Email inválido',
                minPassword: 'Mínimo 6 caracteres',
                invalidDocument: 'CPF/CNPJ no válido',
                invalidDocumentCPF: 'CPF no válido',
                invalidDocumentCNPJ: 'CNPJ no válido',
                invalidPhoneNumber: 'Teléfono no válido',
                invalidZipCode: 'Código postal no válido',
            },
        },
        pages: {
            loading: {
                text: 'Cargando...',
            },
            login: {
                title: 'Bienvenido',
                subtitle: 'Inicia sesión en tu cuenta',
                fields: {
                    createAccount: {
                        text: '¿No tienes una cuenta?',
                        link: 'Regístrate ahora',
                    },
                    keepConnected: 'Mantenme conectado',
                    passwordRecovery: 'Recuperar contraseña',
                    loginButton: 'Entrar',
                },
                footer: {
                    text: '¿No tienes una cuenta?',
                    signup: '¡Registro!',
                },
                success: {
                    message: '¡Conectado!',
                    description: 'Redirigir a inicio del sistema',
                },
            },
            signUp: {
                title: 'Creando tu cuenta',
                subtitle:
                    'Ingrese los siguientes datos para registrarse en el sistema',
                createAccount: 'Crea una cuenta',
                placeholders: {
                    username: 'Su nombre de usuario',
                    email: 'Su correo electrónico',
                    password: 'Su contraseña',
                },
                terms: {
                    text: 'Al hacer clic en <strong>crear cuenta</strong> acepta los',
                    link: 'términos de uso',
                },
                confirm: {
                    cancel: '¿Realmente quieres cancelar el registro?',
                },
            },
            home: {
                title: '¡Bienvenido a la página de inicio!',
            },
            graphs: {
                title: 'Página de Gráficos',
            },
            icons: {
                title: 'Página de Iconos',
            },
            validateEmail: {
                notification: {
                    success: {
                        message: 'Correo electrónico verificado con éxito!',
                        description:
                            '¡Tu correo electrónico ha sido verificado con éxito! Por favor proceda con el registro',
                    },
                    error: {
                        message: 'Error al verificar el correo electrónico...',
                        description:
                            'El enlace al que accedió está caducado o no es válido',
                    },
                },
            },
            createPassword: {
                title: 'Seguridad',
                description:
                    'Regístrese y confirme una contraseña segura para su cuenta',
                fields: {
                    placeholders: {
                        password: 'Introduzca una contraseña válida',
                        confirmPassword: 'Confirmar la contraseña',
                    },
                    rules: {
                        passwordNotMatch: 'Las contraseñas no son similares',
                        passwordMinLength:
                            'La contraseña debe tener al menos 6 caracteres.',
                        passwordMaxLength:
                            'La contraseña debe tener un máximo de 128 caracteres.',
                    },
                },
                confirm: {
                    cancel: '¿Realmente quieres cancelar tu registro?',
                },
            },
            termsOfUse: {
                subtitle: 'Lea y revise los términos de uso',
                carrier: {
                    title: 'Términos de Uso y Consentimiento del Transportista',
                },
                shipper: {
                    title: 'Términos de Uso y Consentimiento del Embarcador',
                },
                fields: {
                    labels: {
                        acceptTerms: 'He leído y acepto las condiciones de uso',
                    },
                },
                action: {
                    cancel: 'No acepto',
                    submit: 'Aceptar y continuar',
                    return: 'Devolver',
                },
                confirm: {
                    cancel: '¿Realmente desea rechazar los términos?',
                },
            },
        },
        enums: {},
        errors: {
            default: {
                message: 'Ha ocurrido un error inesperado',
                description:
                    'Vuelva a cargar la página e intente realizar la acción nuevamente, si el error ocurre nuevamente, comuníquese con nuestro soporte: suporte@suporte.com.br',
            },
            invalidToken: {
                message: 'Token invalido',
                description:
                    'Realice un nuevo acesso en nuestra plataforma para continuar usando el sistema',
            },
        },
    },
};
export default es;
