import { PageHeader as AntPageHeader } from '@ant-design/pro-layout';
import useToken from '../../../hooks/useToken';

interface IPageHeader {
    breadcrumb?: {
        path: string;
        title: string;
    }[];
    title: string;
    subTitle?: string;
    style?: React.CSSProperties;
    [x: string]: unknown;
}

export default function PageHeader({
    breadcrumb,
    title,
    subTitle,
    style,
    ...otherProps
}: IPageHeader) {
    const token = useToken();

    return (
        <AntPageHeader
            title={title}
            breadcrumb={{ items: breadcrumb }}
            subTitle={subTitle}
            style={{
                padding: '16px 24px',
                backgroundColor: token.colorBgContainer,
                ...style,
            }}
            {...otherProps}
        />
    );
}
