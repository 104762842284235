interface ILanguage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const modules = {
    home: 'Home',
    graphs: 'Gráficos',
    icons: 'Ícones',
    account: 'Minha conta',
};

const br: ILanguage = {
    translations: {
        tab: {
            authentication: 'Autenticação',
            signUp: 'Cadastro',
            validateEmail: 'Verificando Email...',
            registerCompany: 'Cadastrar Empresa',
            createPassword: 'Criar Senha',
            termsOfUse: 'Termos de Uso',
            companyCreated: 'Empresa Registrada',
            ...modules,
        },
        languages: {
            ptBR: 'Português',
            enUS: 'English',
            es: 'Español',
        },
        headerMenu: {
            logOut: 'Sair',
        },
        general: {
            logoAlt: 'Logo da Pro Solus',
            yes: 'Sim',
            no: 'Não',
            close: 'Fechar',
            back: 'Voltar',
            save: 'Salvar',
            update: 'Editar',
            cancel: 'Cancelar',
            submit: 'Criar conta',
            confirm: 'Confirmar',
        },
        common: {
            email: 'E-mail',
            password: 'Senha',
            phoneNumber: 'Telefone',
            confirmPassword: 'Confirmar senha',
            username: 'Nome de usuário',
        },
        sidebar: {
            options: { ...modules },
        },
        input: {
            rules: {
                required: 'Campo obrigatório',
                requiredEmail: 'O e-mail é obrigatório',
                requiredPassword: 'A senha é obrigatória',
                requiredUsername: 'O nome de usuário é obrigatório',
                invalidEmail: 'Email inválido',
                minPassword: 'Mínimo 6 caracteres',
                invalidDocument: 'CPF/CNPJ inválido',
                invalidDocumentCPF: 'CPF inválido',
                invalidDocumentCNPJ: 'CNPJ inválido',
                invalidPhoneNumber: 'Telefone inválido',
                invalidZipCode: 'CEP inválido',
            },
        },

        pages: {
            loading: {
                text: 'Carregando...',
            },
            login: {
                title: 'Bem-vindo de volta',
                subtitle: 'Conecte-se à sua conta',
                fields: {
                    createAccount: {
                        text: 'Não tem conta?',
                        link: 'Criar conta agora',
                    },
                    keepConnected: 'Mantenha-me conectado',
                    passwordRecovery: 'Recuperar senha',
                    loginButton: 'Entrar',
                },
                footer: {
                    text: 'Não possui uma conta? ',
                    signup: 'Cadastre-se!',
                },
                success: {
                    message: 'Login efetuado!',
                    description: 'Redirecionando para home do sistema',
                },
            },
            signUp: {
                title: 'Criando sua conta',
                subtitle:
                    'Informe os dados abaixo para se cadastrar no sistema',
                createAccount: 'Criar conta',
                placeholders: {
                    username: 'Seu nome de usuário',
                    email: 'Seu e-mail',
                    password: 'Sua senha',
                    confirmPassword: 'Confirmar senha',
                },

                terms: {
                    text: 'Ao clicar em <strong>criar conta</strong> você concorda com os',
                    link: 'termos de uso',
                },
                confirm: {
                    cancel: 'Você realmente deseja cancelar o cadastro?',
                },
            },
            home: {
                title: 'Bem vindo a Home!',
            },
            graphs: {
                title: 'Página dos Gráficos',
            },
            icons: {
                title: 'Página dos Ícones',
            },
            account: {
                title: 'Minha conta',
                subtitle: 'Verifique seus dados pessoais',
            },
            validateEmail: {
                notification: {
                    success: {
                        message: 'Email verificado com sucesso!',
                        description:
                            'O seu email foi verificado com sucesso! Por favor, prossiga com o cadastro',
                    },
                    error: {
                        message: 'Erro ao verificar email...',
                        description:
                            'O link que você acessou está expirado ou é inválido',
                    },
                },
            },
            createPassword: {
                title: 'Segurança',
                description:
                    'Cadastre e confirme uma senha segura para sua conta',
                fields: {
                    placeholders: {
                        password: 'Informe uma senha válida',
                        confirmPassword: 'Confirme sua senha',
                    },
                    rules: {
                        passwordNotMatch: 'As senhas não são iguais',
                        passwordMinLength:
                            'A senha deve ter no mínimo 6 caracteres',
                        passwordMaxLength:
                            'A senha deve ter no máximo 128 caracteres',
                    },
                },
                confirm: {
                    cancel: 'Você realmente deseja cancelar o cadastro?',
                },
            },
            termsOfUse: {
                subtitle: 'Leia e analise os termos de uso',
                carrier: {
                    title: 'Termos de Uso e Consentimento do Transportador',
                },
                shipper: {
                    title: 'Termos de Uso e Consentimento do Embarcador',
                },
                fields: {
                    labels: {
                        acceptTerms: 'Eu li e concordo com os termos de uso',
                    },
                },
                action: {
                    cancel: 'Não aceito',
                    submit: 'Concordar e continuar',
                },
                confirm: {
                    cancel: 'Você realmente deseja recusar os termos?',
                },
            },
        },
        enums: {},
        errors: {
            default: {
                message: 'Um erro não esperado aconteceu',
                description:
                    'Recarregue a página e tente realizar a ação novamente, caso o erro ocorra novamente entre em contato com nosso suporte: suporte@suporte.com.br',
            },
            invalidToken: {
                message: 'Token inválido',
                description:
                    'Realize um novo login em nossa plataforma para continuar utilizando o sistema',
            },
            400: {
                msg: 'Este usuário já existe',
            },
        },
    },
};
export default br;
