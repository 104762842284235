import { ReactElement } from 'react';

import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

import { t } from '../../../i18n/Translator';

import { useLayoutContext } from '../../../contexts/LayoutContext';
import menuOptions, { IMenuKey } from '../../../utils/menuOptions';

import fullLogo from '../../../assets/icons/pro-solus-full-logo.png';
import smallLogo from '../../../assets/icons/pro-solus-logo.png';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import useToken from '../../../hooks/useToken';

const { Sider } = Layout;

/**
 * @description Sidebar component.
 * @property {boolean} [collapsed=false] If true, sidebar menu collapse
 * @property {string} [layoutColor=null] Layout color
 * @example
 * <Sidebar
 *   collapsed={false}
 *   layoutColor="FFFFFF"
 * />
 */

interface ISidebar {
    collapsed: boolean;
    setCollapsed: (b: boolean) => void;
}

const Sidebar = ({ collapsed, setCollapsed }: ISidebar): ReactElement => {
    const navigate = useNavigate();
    const token = useToken();

    const {
        selectedMenu = '',
        setSelectedMenu = () => {
            return;
        },
    } = useLayoutContext();

    const onMenuClick = (pathTo: string, key: IMenuKey): void => {
        setSelectedMenu(key);

        if (!pathTo) return;
        navigate(pathTo);
    };

    const collapseIconStyle = {
        width: '1em',
        height: '1em',
        transform: !collapsed ? 'translateX(-4px)' : undefined,
    };

    return (
        <Sider
            collapsible
            trigger={null}
            collapsed={collapsed}
            style={{
                position: 'fixed',
                height: '100%',
                zIndex: 2,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 64,
                    backgroundColor: token.layout.backgroundColor,
                }}
            >
                <img
                    style={{
                        transition: '0.2s width',
                        animationTimingFunction: 'ease-in-out',
                        width: '75%',
                        maxWidth: collapsed ? 32 : 92,
                    }}
                    src={collapsed ? smallLogo : fullLogo}
                    alt="Menu Logo"
                    className={collapsed ? 'sidebar_logo-decreased' : ''}
                />
            </div>
            <Menu
                selectedKeys={[selectedMenu]}
                style={{
                    height: '100%',
                    // As propriedades abaixo fazem a sidebar utilizar as cores do layout
                    // background: 'transparent',
                    // color: token.layout.textColor,
                }}
                mode="inline"
                items={[
                    ...menuOptions.map(
                        ({ titleKey, icon, menuKey, pathTo }) => ({
                            icon: icon,
                            key: menuKey,
                            onClick: () => onMenuClick(pathTo, menuKey),
                            label: t(titleKey),
                        })
                    ),
                    {
                        key: '',
                        style: {
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: 0,
                        },
                        title: collapsed ? 'Abrir' : undefined,
                        onClick: () => {
                            setCollapsed(!collapsed);
                        },
                        label: collapsed ? (
                            <MenuUnfoldOutlined style={collapseIconStyle} />
                        ) : (
                            <MenuFoldOutlined style={collapseIconStyle} />
                        ),
                    },
                ]}
            />
        </Sider>
    );
};

export default Sidebar;
