import { User } from '@supabase/supabase-js';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { supabase } from '../../api/supabase';
import { IAuthToken, ILogin, ISignUp } from '../../utils/interfaces';

export interface ISupaTokenType {
    token?: string | null;
}

class UserController {
    static uploadUserData(
        token: string | null | undefined,
        keepConnected = false
    ): Promise<IAuthToken & JwtPayload> {
        return new Promise((resolve, reject) => {
            try {
                const decodedToken = jwtDecode<IAuthToken & JwtPayload>(
                    `${token}`
                );

                localStorage.setItem('token', `"${token}"`);
                localStorage.setItem('keepConnected', `${keepConnected}`);

                resolve(decodedToken);
            } catch (e) {
                reject({
                    message: 'errors.invalidToken.message',
                    description: 'errors.invalidToken.description',
                });
            }
        });
    }

    static signUp = ({
        username,
        email,
        password,
    }: ISignUp): Promise<ISupaTokenType> => {
        return new Promise((resolve, reject) => {
            supabase.auth
                .signUp({
                    email: email,
                    password: password,
                    options: {
                        data: {
                            username: username,
                        },
                    },
                })
                .then(({ data, error }) => {
                    const token = data?.session?.access_token;
                    if (token) {
                        resolve({ token });
                    } else {
                        reject(error);
                    }
                })
                .catch(error => reject(error));
        });
    };
    static login = ({ email, password }: ILogin): Promise<ISupaTokenType> => {
        return new Promise((resolve, reject) => {
            supabase.auth
                .signInWithPassword({
                    email: email,
                    password: password,
                })
                .then(({ data, error }) => {
                    const token = data?.session?.access_token;
                    if (token) {
                        resolve({ token });
                    } else {
                        reject(error);
                    }
                })
                .catch(error => reject(error));
        });
    };

    static getUser = (): Promise<{ user: User | null }> => {
        return new Promise((resolve, reject) => {
            supabase.auth
                .getUser()
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        });
    };
}

export default UserController;
