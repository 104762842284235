/**
 * Theme Configuration
 * {token} is the configProvider token used in antd
 */
const theme = {
    token: {
        colorPrimary: '#604787',
        colorInfo: ' #604787',
    },
};

/**
 * The custom theme is used to define properties that aren't in the original token.
 * The custom properties will be added to the token according to the theme (light, dark).
 * Also the light and dark properties should have the same structure, differing only in values, for better readability.
 */
const customTheme = {
    light: {
        layout: {
            backgroundColor: '#001529',
            textColor: '#dadcdf',
        },
    },
    dark: {
        layout: {
            backgroundColor: '#001529',
            textColor: '#dadcdf',
        },
    },
};

module.exports = {
    theme,
    customTheme,
};
