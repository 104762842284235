import { ReactElement } from 'react';

import Routes from './Routes';
import Providers from './providers';
import { BrowserRouter } from 'react-router-dom';

function App(): ReactElement<unknown> {
    return (
        <BrowserRouter>
            <Providers>
                <Routes />
            </Providers>
        </BrowserRouter>
    );
}

export default App;
