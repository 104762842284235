import { Layout } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLayoutContext } from '../../../contexts/LayoutContext';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

const { Content } = Layout;

/**
 * @description Panel Main layout (Sidebar and header).
 * @property {Node} children Main layout children (content)
 * @example
 * <MainLayout>
 *   {children}
 * </MainLayout>
 */

interface IMainLayout {
    children: React.ReactNode;
}

const MainLayout = ({ children }: IMainLayout): ReactElement => {
    const { screenWidth = 0 } = useLayoutContext();

    // Controle da animação menu-content
    const [collapsed, setCollapsed] = useState(false);

    const contentSmallStyle: React.CSSProperties = {
        minHeight: 'calc(100% - 64px)',
        marginTop: 64,
        width: '100%',
        paddingLeft: 80,
        transition: '0.2s all',
        animationTimingFunction: 'ease-out',
        position: 'relative',
    };

    const contentLargeStyle: React.CSSProperties = {
        minHeight: 'calc(100% - 64px)',
        marginTop: 64,
        width: '100%',
        paddingLeft: 200,
        transition: '0.2s all',
        animationTimingFunction: 'ease-out',
        position: 'relative',
    };
    //final do controle de animação menu-content

    useEffect(() => {
        if (screenWidth < 550) setCollapsed(true);
    }, [screenWidth]);

    return (
        <Layout>
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
            <Layout>
                <Header collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content
                    style={collapsed ? contentSmallStyle : contentLargeStyle}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
