import { Button, Card, Col, Input, Layout, Row } from 'antd';
import { t } from 'i18next';
import { ReactElement } from 'react';

import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../components/layout/pageHeader/PageHeader';

const routes = [
    {
        path: 'index',
        title: 'First-level Menu',
    },
    {
        path: 'first',
        title: 'Second-level Menu',
    },
    {
        path: 'second',
        title: 'Third-level Menu',
    },
];

function btnHome() {
    return (
        <Button type="primary" htmlType="submit">
            {t('pages.home.title')}
        </Button>
    );
}

const Home = ({ t }: { t: TFunction }): ReactElement<unknown> => {
    return (
        <Layout style={{ height: 'calc(100vh - 64px)', overflowX: 'hidden' }}>
            <PageHeader
                title={t('pages.home.title')}
                breadcrumb={routes}
                subTitle="This is a subtitle"
            />
            <Row gutter={[24, 24]} style={{ padding: 24 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Card">
                        <Row gutter={[16, 8]} style={{ padding: 12 }}>
                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={24}>{btnHome()}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Card">
                        <Row gutter={[16, 8]} style={{ padding: 12 }}>
                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={24}>{btnHome()}</Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title="Card">
                        <Row gutter={[16, 8]} style={{ padding: 12 }}>
                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={12}>
                                <Input placeholder="placeholder" />
                            </Col>

                            <Col span={24}>{btnHome()}</Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default withTranslation()(Home);
