import { GlobalToken, theme } from 'antd';
import { customTheme } from '../utils/constants/themeConfig';

/**
 * Wrapper Hook for antd useToken that combines the types of GlobalToken with the customToken
 */
export default function useToken() {
    const { token } = theme.useToken();
    return token as GlobalToken &
        typeof customTheme.dark &
        typeof customTheme.light;
}
