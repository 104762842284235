import { ReactElement, ReactNode } from 'react';

import { Col, Row } from 'antd';

import proSolusLogo from '../../../assets/logos/prosolus-full-logo.svg';

interface IAuthContainerProps {
    className?: string;
    children: ReactNode;
    style?: React.CSSProperties;
    logoColumnSize?: 'middle' | 'small';
}

import './index.scss';
import useToken from '../../../hooks/useToken';
import I18n from '../../shared/i18n';

const AuthContainer = ({
    children,
    className,
    logoColumnSize = 'middle',
    style,
}: IAuthContainerProps): ReactElement => {
    const token = useToken();

    const logoCol = (
        <Col
            className={`logo-col${
                logoColumnSize === 'small' ? ' logo-col-small' : ''
            }`}
            style={{
                background: `linear-gradient(to bottom right, ${token.colorPrimary} 30%, ${token.colorPrimary} )`,
            }}
        >
            <div className="logo-container">
                <img src={proSolusLogo} alt="Pro solus logo" />
            </div>
        </Col>
    );

    return (
        <div>
            <I18n />
            <Row
                style={style}
                className={`auth-container${className ? ` ${className}` : ''}`}
            >
                {logoCol}
                <Col className="form-col">{children}</Col>
            </Row>
        </div>
    );
};

export default AuthContainer;
