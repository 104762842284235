import { Layout, Row, Col, Card, Input, Button } from 'antd';
import { t } from 'i18next';
import { ReactElement } from 'react';

import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import PageHeader from '../../components/layout/pageHeader/PageHeader';

const routes = [
    {
        path: 'index',
        title: 'First-level Menu',
    },
    {
        path: 'first',
        title: 'Second-level Menu',
    },
    {
        path: 'second',
        title: 'Third-level Menu',
    },
];

function btnHome() {
    return (
        <Button type="primary" htmlType="submit">
            {t('pages.icons.title')}
        </Button>
    );
}

const IconsPage = ({ t }: { t: TFunction }): ReactElement<unknown> => (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
        <PageHeader
            title={t('pages.icons.title')}
            breadcrumb={routes}
            subTitle="This is a subtitle"
        />
        <Row gutter={[24, 24]} style={{ padding: 24 }}>
            <Col span={12}>
                <Card title="Card">
                    <Row gutter={[16, 16]} style={{ padding: 10 }}>
                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={24}>{btnHome()}</Col>
                    </Row>
                </Card>
            </Col>

            <Col span={12}>
                <Card title="Card">
                    <Row gutter={[16, 16]} style={{ padding: 10 }}>
                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={24}>{btnHome()}</Col>
                    </Row>
                </Card>
            </Col>

            <Col span={24}>
                <Card title="Card">
                    <Row gutter={[16, 16]} style={{ padding: 10 }}>
                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={12}>
                            <Input placeholder="placeholder" />
                        </Col>

                        <Col span={24}>{btnHome()}</Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </Layout>
);

export default withTranslation()(IconsPage);
