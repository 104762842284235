import {
    UserOutlined,
    UploadOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';

export type IMenuKey = 'home' | 'graphs' | 'icons' | 'account';

export interface IMenuOption {
    titleKey: string;
    icon: JSX.Element;
    pathTo: string;
    menuKey: IMenuKey;
}

const menuOptions: IMenuOption[] = [
    {
        titleKey: 'sidebar.options.home',
        icon: <UserOutlined />,
        pathTo: '/home',
        menuKey: 'home',
    },
    {
        titleKey: 'sidebar.options.graphs',
        icon: <UploadOutlined />,
        pathTo: '/graphs',
        menuKey: 'graphs',
    },
    {
        titleKey: 'sidebar.options.icons',
        icon: <VideoCameraOutlined />,
        pathTo: '/icons',
        menuKey: 'icons',
    },
    {
        titleKey: 'sidebar.options.account',
        icon: <UserOutlined />,
        pathTo: '/account',
        menuKey: 'account',
    },
];

export default menuOptions;
