import { Button, Card, Col, Descriptions, Layout, Row } from 'antd';
import { ReactElement, useState } from 'react';

import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import PageHeader from '../../components/layout/pageHeader/PageHeader';
import { useUserContext } from '../../contexts/UserContext';
import UpdateUserAccount from './updateUserAccount/UpdateUserAccount';

const { Item } = Descriptions;

const Account = ({ t }: { t: TFunction }): ReactElement<unknown> => {
    const [visible, setVisible] = useState(false);
    const { userData } = useUserContext();

    const userName = userData?.user?.user_metadata.username;
    const userEmail = userData?.user?.email;

    return (
        <>
            <Layout
                style={{ height: 'calc(100vh - 64px)', overflowX: 'hidden' }}
            >
                <PageHeader
                    title={t('pages.account.title')}
                    subTitle={t('pages.account.subtitle')}
                />
                <Row gutter={[24, 24]} style={{ padding: 24 }}>
                    <Col span={4}>
                        <Card>
                            <Descriptions layout="vertical">
                                <Item
                                    label={t('common.username')}
                                    children={userName}
                                />
                            </Descriptions>

                            <Descriptions layout="vertical">
                                <Item
                                    label={t('common.email')}
                                    children={userEmail}
                                />
                            </Descriptions>

                            <br />
                            <Button onClick={() => setVisible(true)}>
                                {t('general.update')}
                            </Button>
                        </Card>
                    </Col>
                </Row>
            </Layout>
            <UpdateUserAccount visible={visible} setVisible={setVisible} />
        </>
    );
};

export default withTranslation()(Account);
