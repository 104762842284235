interface ILanguage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

const modules = {
    home: 'Home',
    graphs: 'Graphs',
    icons: 'Icons',
    account: 'My account',
};

const en: ILanguage = {
    translations: {
        tab: {
            authentication: 'Authentication',
            signUp: 'Sign up',
            validateEmail: 'Checking email...',
            registerCompany: 'Register organization',
            createPassword: 'Create password',
            termsOfUse: 'Terms of Use',
            companyCreated: 'Organization registered',
            ...modules,
        },
        languages: {
            ptBR: 'Português',
            enUS: 'English',
            es: 'Español',
        },
        headerMenu: {
            logOut: 'Logout',
        },
        general: {
            logoAlt: 'Pro Solus logo',
            yes: 'Yes',
            no: 'No',
            close: 'Close',
            back: 'Back',
            save: 'Save',
            cancel: 'Cancel',
            submit: 'Create account',
            confirm: 'Confirm',
            update: 'Update',
        },
        common: {
            email: 'Email',
            password: 'Password',
            phoneNumber: 'Phone number',
            confirmPassword: 'Confirm password',
            username: 'Username',
        },
        sidebar: {
            options: { ...modules },
        },
        input: {
            rules: {
                required: 'Required field',
                requiredEmail: 'Email is required',
                requiredPassword: 'Password is required',
                requiredUsername: 'Username is required',
                invalidEmail: 'Invalid email',
                minPassword: 'Minimum length of 6 characters',
                invalidDocument: 'Invalid CPF/CNPJ',
                invalidDocumentCPF: 'Invalid document CPF',
                invalidDocumentCNPJ: 'Invalid document CNPJ',
                invalidPhoneNumber: 'Invalid phone number',
                invalidZipCode: 'Invalid zip code',
            },
        },
        pages: {
            loading: {
                text: 'Loading...',
            },
            login: {
                title: 'Welcome back',
                subtitle: 'Sign in to your account',
                fields: {
                    createAccount: {
                        text: 'No account?',
                        link: 'Create account now',
                    },
                    keepConnected: 'Keep me connected',
                    passwordRecovery: 'Recovery password',
                    loginButton: 'Sign in',
                },
                footer: {
                    text: "Don't have an account?",
                    signup: 'Sign up!',
                },
                success: {
                    message: 'Login done!',
                    description: 'Redirecting to Home',
                },
            },
            signUp: {
                title: 'Create your account',
                subtitle: 'Enter your data below to create your account.',
                createAccount: 'Create account',
                placeholders: {
                    username: 'Your username',
                    email: 'Your email',
                    password: 'Your password',
                    confirmPassword: 'Confirm password',
                },

                terms: {
                    text: 'By clicking on <strong>create account</strong> you agree to the',
                    link: 'terms of use',
                },
                confirm: {
                    cancel: 'Do you really want to cancel the registration?',
                },
            },
            home: {
                title: 'Welcome to Home!',
            },
            graphs: {
                title: 'Graphs page',
            },
            icons: {
                title: 'Icons page',
            },
            validateEmail: {
                notification: {
                    success: {
                        message: 'Email verified successfully!',
                        description:
                            'Your email has been successfully verified! Please proceed with registration',
                    },
                    error: {
                        message: 'Error checking email...',
                        description:
                            'The link you accessed is expired or invalid',
                    },
                },
            },
            createPassword: {
                title: 'Security',
                description:
                    'Register and confirm a secure password for your account',
                fields: {
                    placeholders: {
                        password: 'Inform a valid password',
                        confirmPassword: 'Confirm your password',
                    },
                    rules: {
                        passwordNotMatch: 'Passwords are not the same',
                        passwordMinLength:
                            'The password must be at least 6 characters long',
                        passwordMaxLength:
                            'The password must be a maximum of 128 characters',
                    },
                },
                confirm: {
                    cancel: 'Do you really want to cancel your registration?',
                },
            },
            termsOfUse: {
                subtitle: 'Read and review the terms of use',
                carrier: {
                    title: 'Carrier Terms of Use and Consent',
                },
                shipper: {
                    title: 'Shipper Terms of Use and Consent',
                },
                fields: {
                    labels: {
                        acceptTerms:
                            'I have read and agree to the terms of use',
                    },
                },
                action: {
                    cancel: "I don't accept",
                    submit: 'Agree and continue',
                    return: 'Go back',
                },
                confirm: {
                    cancel: 'Do you really want to decline the terms?',
                },
            },
        },
        enums: {},
        errors: {
            default: {
                message: 'An unexpected error has occurred',
                description:
                    'Reload the page and try to perform the action again, if the error persists, contact our SAC Support: suporte@suporte.com.br',
            },
            invalidToken: {
                message: 'Invalid token',
                description: 'Log in again to keep using the system',
            },
            400: {
                msg: 'User already exists',
            },
        },
    },
};
export default en;
