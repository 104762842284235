import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportedLocale } from '../i18n/locales';
import { ConfigProvider, theme } from 'antd';

import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import ptBR from 'antd/lib/locale/pt_BR';

import { theme as config, customTheme } from '../utils/constants/themeConfig';

interface IContext {
    darkMode: boolean;
    setDarkMode: (x: boolean) => void;
}

const ThemeContext = React.createContext<IContext>({
    darkMode: false,
    setDarkMode: () => {
        return;
    },
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
    const { i18n } = useTranslation();

    const language = i18n.language as ISupportedLocale;

    const currentLocale = {
        'pt-BR': ptBR,
        'en-US': enUS,
        es: esES,
    };

    const isAlreadyDark = JSON.parse(
        localStorage.getItem('darkMode') || 'false'
    );

    const [darkMode, setDarkMode] = useState(isAlreadyDark);

    const defineDarkMode = (status: boolean): void => {
        localStorage.setItem('darkMode', `${status}`);
        setDarkMode(status);
    };

    const tokenWithCustomTheme = {
        token: {
            ...config.token,
            ...customTheme[darkMode ? 'dark' : 'light'],
        },
    };

    return (
        <ThemeContext.Provider
            value={{ setDarkMode: defineDarkMode, darkMode }}
        >
            <ConfigProvider
                theme={{
                    algorithm: darkMode
                        ? theme.darkAlgorithm
                        : theme.defaultAlgorithm,
                    ...tokenWithCustomTheme,
                }}
                locale={currentLocale[language]}
            >
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
}

export const useThemeContext = () => React.useContext(ThemeContext);
